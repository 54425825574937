<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :md="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px; " class="pull-left">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：打印-销售开单</strong>
                                <el-col :md="3">
                                    <a style="padding-left: 15px">仓库:</a>
                                    <el-select style="margin-left: 8px;width: 80px" v-model="warehouseBackOrGo.name" no-data-text="没有搜索结果!" placeholder="全部"
                                               default-first-option size="mini"
                                               @change="warehouseBackOrGo.id = $event.id,warehouseBackOrGo.name = $event.name"
                                               @focus="$_searcWarehouseList" clearable filterable>
                                        <el-option v-for="item in this.$store.getters.getWarehouseList" :key="item.id" :label="item.name"
                                                   :value="item">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <!--
                                @change="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Back',true,companyBackOrGo)"
                                @keyup.native.enter="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Back',true,companyBackOrGo)"
                                -->
                                <el-col :md="4">
                                        <el-select ref="customer" v-model="companyBackOrGo.company_name" style="width: 100%"
                                                   default-first-option remote :remote-method="$_searchCustomerList" clearable
                                                   @change="companyBackOrGo.company_name = $event.customer_name,companyBackOrGo.company_uuid = $event.company_uuid"
                                                   filterable size="mini" placeholder="可搜索查询">
                                            <el-option
                                                    v-for="item in $store.getters.getCustomerList"
                                                    :key="item.id"
                                                    :label="item.customer_name"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                </el-col>
                                <el-col :md="8">
                                    <el-button-group style="margin-left: 8px">
                                        <el-button size="mini" type="success" title="上一页" @click="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Back',true,companyBackOrGo)" icon="el-icon-caret-left"></el-button>
                                        <el-button size="mini" type="success" title="下一页" @click="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Go',true,companyBackOrGo)" icon="el-icon-caret-right"></el-button>
                                    </el-button-group>
                                </el-col>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row style="margin-bottom: 20px">
                        <el-button type="primary" size="mini" @click="lodopPrint"
                                   style="margin-bottom: 2px;margin-top: 2px">针式插件打印
                        </el-button>
                        <el-button type="primary" size="mini" @click="$router.back()"
                                   style="margin-bottom: 2px;margin-top: 2px">返回
                        </el-button>
                    </el-row>
                </el-col>
            </el-row>
            <div v-if="showPrint">
                <div style="width: 100%;margin-right: 50px;float: left" v-for="index of pageSize" :key="index">
                <div id="myMain" style="width: 100%" ref="myMain">
                    <div :id="'tableDataMain'+index">
                        <div class="topHtml">
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 35mm;">
                                    <div style="border: solid 0.2mm;text-align: center !important;width: 32mm;">
                                        <div class="my-list-row">
                                            <a style=" margin: initial; ">货物当面点收</a>
                                        </div>
                                        <div class="my-list-row">
                                            <a style=" margin: initial; ">如 有 破 损</a>
                                        </div>
                                        <div class="my-list-row">
                                            <a style=" margin: initial; ">出门恕不退换</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="my-list-col" style="width: 130mm;">
                                    <div class="my-list-row" style="text-align: center">
                                        <strong style=" font-size: 5mm ;">{{!isBlankVue(formData.tax_tate_warehouse_bill) || !isBlankVue(formData.taxes_fees_second_string)? formData.billing_company_name : (formData.clearing_form == '欠款' ? '欠款单(' +formData.name_print +')':'调拨单(' +formData.name_print +')')}}</strong>
                                        <img border="0" src="@/assets/test.png" v-if="$store.getters.getRunTimeTest"
                                             style="z-index: -1; position: absolute; left:100px; top:200px;"/>
                                        <img border="0" src="@/assets/zf.png" v-if="formData.is_cancel"
                                             style="z-index: -1; position: absolute; left:200px; top:150px;"/>
                                    </div>
                                    <div class="my-list-row" style="font-size: 5mm;text-align: center">
                                        <strong v-if="!isBlankVue(formData.tax_tate_warehouse_bill) || !isBlankVue(formData.taxes_fees_second_string)" style="margin-left: 38mm">{{formData.clearing_form == '欠款' ? '欠款单':'调拨单'}}</strong>
                                        <a style="float: right;font-size: 4mm;">No：{{formData.bill_id_kd}}</a>
                                    </div>
                                </div>
                                <div v-if="formData.tax_tate_warehouse_bill != '' && formData.tax_tate_warehouse_bill != null"
                                     class="my-list-col" style="width: 20mm;">
                                    <div style="border: solid 0.2mm;text-align: left !important;width: 28mm;margin-left: 3mm;">
                                        <div class="my-list-row">
                                            <a style=" margin: initial; ">凭此单开发票</a>
                                        </div>
                                        <div class="my-list-row">
                                            <a style=" margin: initial; ">45天内 有 效</a>
                                        </div>
                                        <div class="my-list-row">
                                            <a style=" margin: initial; ">过 期 作 废</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 200mm;">
                                    <a style="width: 140mm;">购货欠款单位或个人:{{formData.customer}}</a>
                                    <a style="width: 10mm;float: right;" title="A含税，B不含税">{{!isBlankVue(formData.taxes_fees_second_string) ? '' :  isBlankVue(formData.tax_tate_warehouse_bill) ? 'B':'A'}}</a>
                                    <a style="width: 65mm;float: right;">日期:{{formatDate(formData.bill_date_kd,'yyyy年MM月dd日')}}</a>

                                </div>
                            </div>
                        </div>
                        <table cellspacing="0" style="width: 204mm;border: none">
                            <tr>
                                <!--<th rowspan="16" style="border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;line-height: 4mm;width: 1mm;font-size: 3mm; font-weight: initial;border-left: none;border-top: none;border-bottom: none">
                                    <a> m n e r p ▪ c o m  提 供 软 件 服 务</a>
                                </th>-->
                                <!--<th style="width: 10mm;border: solid 0.3mm;border-bottom: 0mm;text-align: center !important;">序号</th>-->
                                <!--<th style="height: 5mm;width: 17mm;border: solid 0.3mm;border-bottom: 0mm;text-align: center !important;">仓库</th>-->
                                <th style="height: 5mm;width: 50mm;border: solid 0.3mm;border-bottom: 0mm;text-align: center !important;">
                                    物资名称及规格
                                </th>
                                <th style="height: 5mm;width: 15mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">
                                    件数
                                </th>
                                <th style="height: 5mm;width: 15mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">
                                    单位
                                </th>
                                <th style="height: 5mm;width: 15mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">
                                    数量
                                </th>
                                <th style="height: 5mm;width: 15mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">
                                    单价
                                </th>
                                <th style="height: 5mm;width: 25mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">
                                    金额
                                </th>
                                <th style="width: 50mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;"
                                    :rowspan="index == pageSize && !formData.is_contract_fees ? 12: 11">
                                    <div class="my-list-row"
                                         style=" margin-bottom: 10mm;margin-top: 5mm;margin-left: 1mm; text-align: left;">
                                        <a>结算：{{formData.clearing_form}}</a>
                                    </div>
                                    <div class="my-list-row"
                                         style=" margin-bottom: 10mm;margin-left: 1mm;text-align: left; ">
                                        <a style=" margin: initial; ">提货人：</a>
                                    </div>
                                    <div class="my-list-row"
                                         style=" margin-bottom: 10mm;margin-left: 1mm; text-align: left;">
                                        <a style=" margin: initial; ">车号：</a>
                                    </div>
                                    <div class="my-list-row"
                                         style=" margin-bottom: 10mm;margin-left: 1mm;text-align: left; ">
                                        <a style=" margin: initial; ">电话：</a>
                                    </div>
                                </th>
                                <th rowspan="16"
                                    style="line-height: 4mm;width: 1mm;font-size: 3mm; font-weight: initial;border-right: none;border-top: none;border-bottom: none">
                                    <a> 白联 ：存根 。红联 ：客户 。黄联 ：财务 。绿联 ：仓库 。{{formData.payment_days}}</a>
                                </th>
                            </tr>
                            <tr style="width: 204mm; height: 5mm; " v-for="item in table[index-1]" :key="item.order_id">
                                <!--<td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;"><a>{{item.order_id}}</a></td>-->
                                <!--<td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;"><a>{{item.warehouse_out}}</a></td>-->
                                <td style="text-align: left !important;border: solid 0.3mm;border-bottom: 0mm;"><a>{{item.category}}
                                    {{item.a_side && item.actual_thickness ?item.a_side+'*'+item.b_side+'*'+item.actual_thickness +'*'+item.length_extent:item.specification}}</a>
                                </td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;">
                                    <a>{{item.unit == '支' ? '' : item.single_count}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;">
                                    <a>{{item.unit}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;">
                                    <a>{{item.unit == '支' ? item.single_count : item.weighing_heavy}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;">
                                    <a>{{item.price != null && item.price != '' ?
                                        howPoint(item.price) == 3 ? $XEUtils.commafy(item.price, {digits:3}) : $XEUtils.commafy(item.price, {digits:2}) : ''
                                        }}</a></td>
                                <td style="text-align: right !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;">
                                    <a>{{item.amount_of_product != null && item.amount_of_product != '' ?
                                        '¥'+formatMoney(item.amount_of_product):''}}</a></td>
                            </tr>
                            <tr v-show="index == pageSize && !formData.is_contract_fees" style="height: 5mm;">
                                <td style="border: solid 0.3mm;border-bottom: 0mm;" colspan="5">
                                    <a style="margin-right: 5mm" v-show="index == pageSize && !formData.is_contract_fees">
                                        {{(!isBlankVue(formData.taxes_fees_second_string) ? '后补税金:' + formatMoney(formData.taxes_fees_second_string)+ '元' : '')
                                         + (!isBlankVue(formData.pounds_fees) ? '磅费:' + formatMoney(formData.pounds_fees) + '元' : '')
                                         +  (!isBlankVue(formData.wind_cut_fees) ? '  风割费:' + formatMoney(formData.wind_cut_fees)+ '元' : '')
                                         + (!isBlankVue(formData.loading_car_fees) ? '  装车费:' +  formatMoney(formData.loading_car_fees) + '元' : '')
                                         +  (!isBlankVue(formData.transfer_fees) ? '  中转费:' + formatMoney(formData.transfer_fees) + '元' : '')
                                         + (!isBlankVue(formData.freight_fees) ? '  运费:' +  formatMoney(formData.freight_fees) + '元' : '')
                                         + (!isBlankVue(formData.procedure_fees) ? '  手续费:' +  formatMoney(formData.procedure_fees) + '元' : '')
                                        }}
                                    </a>
                                </td>
                                <td style="text-align: right !important;border: solid 0.3mm;border-bottom: 0mm;border-left: solid 0mm;">
                                    <a>{{!isBlankVue(formData.all_fees) ? '¥' +formatMoney(formData.all_fees) : ''}}</a></td>
                            </tr>
                            <tr>
                                <!--<td style="border: solid 0.3mm;border-bottom: 0mm;" colspan="1">
                                    <a style="margin-right: 5mm" v-show="index == pageSize && !formData.is_contract_fees">
                                        &lt;!&ndash;dataSizeOriginal等于0代表是税金单，只显示税金&ndash;&gt;
                                        {{dataSizeOriginal == 0 ? '税金：'+ formatMoney($data.tableAmount[index-1]) : '风割费：xx元  装车费：xx元 磅费：xx元 中转费：xx元 运费:xx元'}}元
                                    </a>
                                </td>-->
                                <td style="border: solid 0.3mm;border-top: solid 0.3mm;border-right: solid 0.3mm;border-bottom: 0mm;"
                                    colspan="6">
                                    <a style="float: left;margin-right: 5mm">本页合计：{{$_digitUppercase($data.tableAmount[index-1])}}</a>
                                </td>
                                <td style="text-align:left !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"
                                    colspan="1">
                                    <a>本页合计：{{formatMoney($data.tableAmount[index-1])}}元</a>
                                </td>
                            </tr>
                            <!--<tr>
                                <td style="border: solid 0.3mm;border-bottom: 0mm;" colspan="1">
                                    <a style="margin-right: 5mm" v-show="formData.is_contract_fees == 0">
                                        合计费用：{{!isBlankVue(formData.all_fees) ? formatMoney(formData.all_fees) : '0'}}元
                                    </a>
                                </td>
                                <td style="border-top: solid 0.3mm;border-right: solid 0.3mm;border-bottom: 0mm;" colspan="5">
                                    <a style="float: left;margin-right: 5mm">总计：{{$_digitUppercase(formData.total_amount)}}</a>
                                </td>
                                <td style="text-align:left !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;" colspan="1">
                                    <a>总计：{{formatMoney(formData.total_amount)}}元</a>
                                </td>
                            </tr>-->
                            <tr>
                                <td style="border: solid 0.3mm;border-bottom: 0mm;" colspan="7">上列物资款
                                    {{formData.settlement_days != '' && formData.settlement_days != null ?
                                    formData.settlement_days : '0'}} 天内结清，逾期每天按总金额10%计算。
                                </td>
                            </tr>
                            <!--<tr>
                                <td style="border: solid 0.3mm;border-bottom: 0mm;" colspan="7">风割费：xx元  装车费：xx元
                                </td>
                            </tr>-->
                            <tr>
                                <td style="border: solid 0.3mm;" colspan="1">随货同行产品质量证明书，只作参考不作担保。</td>
                                <td style="border: solid 0.3mm;border-left: 0mm;" colspan="3">
                                    开单员：{{formData.part_order}}<br>
                                    业务员：{{formData.sales_man}}
                                </td>
                                <td style="border: solid 0.3mm;border-left: 0mm;font-size: 3.8mm" colspan="3">
                                    提货人签名后具有法律效力，如有质量等问题，<br>收货三天内提出异议，否则不予以受理。
                                </td>
                            </tr>
                        </table>
                        <div class="my-list-row" style="width: 204mm;">
                            <a style="width: 150mm">{{formData.tax_tate_warehouse_bill != '' && formData.tax_tate_warehouse_bill
                                != null ? formData.remark_warehouse_bill :''}}</a>

                            <!--<a style="width: 150mm">{{formData.remark_warehouse_bill != '' && formData.remark_warehouse_bill
                                != null ? formData.remark_warehouse_bill :''}}</a>-->
                            <a style="width: 50mm;float: right">第{{index}}页，共{{pageSize}}页。</a>
                        </div>
                    </div>

                </div>
            </div>
            </div>
            <p v-else>数据加载中ing，超过两分钟请刷新页面，可能是网络问题！</p>
        </el-main>
    </div>
</template>

<script>


    export default {

        data() {
            return {
                showPrint:false,
                companyBackOrGo:{
                    company_name:'',
                    company_uuid:''
                },
                warehouseBackOrGo:{
                    id:'',
                    name:'',
                },//仓库查询 上一张下一张
                table: [],
                tableDataMain: [],
                tableAmount: [],
                checkList: [],
                pageSize: 1,
                dataSize: 0,
                dataSizeOriginal: 0,//有多少行数据
                clearingFormList: ['微信'],
                formData: {
                    bill_id_type: 'KD',
                    clearing_form: '欠款',
                    other_expenses : '费用',
                    other_expenses_price : '',
                    customer_phone: '',
                    car_code : '',
                    bill_date: '',
                    bill_date_kd : '',
                    bill_date_dd : '',
                    bill_date_kdtzs : '',
                    contract_id : '',
                    delivery_date: '',
                    kick_back : '',
                    total_theoretical_weight: '',
                    bill_id_dd: '',
                    bill_id_kd : '',
                    bill_id_kdtzs : '',
                    bill_id: '',
                    settlement_days: '',
                    customer: '',
                    company_uuid: '',
                    sales_man : '',
                    billing_company_name: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_amount: '',
                    practical_total_price : '',
                    total_number: '',
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '发货单',
                    creat_date_warehouse_bill : '',
                    handled_by: '',
                    consignee: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    additional_charges : '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    total_weighing_heavy : '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    taxes_fees : '',//税金
                    all_fees: '',//合计费用
                    is_cancel: false,
                    taxes_fees_second_string : 0,
                    name_print: '',//打印的中文名
               },
                input: ''
           }
       },
        methods: {
            lodop() {
                //打印单
                const LODOP = this.$getLodop()
                const strStyle = "<style>.my-list-row {display: inline-block; width: 100%;} .my-list-col {float: left; width: 33.33%;} .my-top,.my-bottom {font-size: 12px;} .my-top {margin-bottom: 5px;} .my-bottom {margin-top: 30px; text-align: right;}</style>"
                LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Width");
               // LODOP.SET_PRINT_PAGESIZE(1, 2100, 1400, "");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                for (let i = 1; i < this.pageSize + 1; i++) {
                    if (i > 1) {
                        LODOP.NewPageA();
                   }
                    LODOP.ADD_PRINT_HTM(20, 0, "100%", "100%","<!DOCTYPE html>"+ strStyle + "<body>" + document.getElementById("tableDataMain" + i).innerHTML + "</body>");
                    LODOP.SET_PRINT_PAGESIZE(1, 2100, 1400, "");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
               }
                return LODOP;
           },
            lodopPrint() {
                const LODOP = this.lodop()
                LODOP.PREVIEW()
           },
            splitTableDataMain() {
                let list = [];
                let listAmount = 0;
                let currentPage = 1;
                for (let i = 0; i < this.tableDataMain.length; i++) {
                    list.push(this.tableDataMain[i]);
                    listAmount = this.$XEUtils.add(listAmount, this.tableDataMain[i].amount_of_product);
                    if (list.length == 10) {
                        this.table.push(list);
                        if (currentPage == this.pageSize && !this.formData.is_contract_fees){
                            //console.log("currentPage: " + currentPage)
                            listAmount = this.$XEUtils.add(listAmount,this.formData.all_fees);
                       }
                        if (currentPage == this.pageSize && this.dataSizeOriginal == 0){
                            //说明是后补税金单
                            listAmount = this.$XEUtils.add(listAmount,this.formData.taxes_fees_second_string);
                       }
                        this.tableAmount.push(listAmount);
                        list = [];
                        listAmount = 0;
                        currentPage = currentPage + 1;
                   }
               }
                if (currentPage == this.pageSize && !this.formData.is_contract_fees){
                    listAmount = this.$XEUtils.add(listAmount,this.formData.all_fees);
               }
                //console.log(this.dataSizeOriginal)
                //console.log(this.formData.taxes_fees_second_string)
                if (currentPage == this.pageSize && this.dataSizeOriginal == 0){
                    //说明是后补税金单
                    listAmount = this.$XEUtils.add(listAmount,this.formData.taxes_fees_second_string);
               }
                this.$_createRowData(list,10);
                this.table.push(list);
                this.tableAmount.push(listAmount);
                this.showPrint = true;
           },
            indexMethod(index) {
                return index + 1;
           }, searchWarehouseBill() {
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlip/search',
                    params: {
                        print:true,
                        bill_id_kd : this.formData.bill_id_kd,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks,10);
                        //this.tableDataMain = response.data.stocks;
                        this.dataSize = this.tableDataMain.length;
                        this.dataSizeOriginal = response.data.dataSize;
                        this.checkList = [];
                        if (this.formData.clearing_form == '欠款') {
                            this.checkList.push('欠款单')
                       } else {
                            this.checkList.push('调拨单')
                       }
                        this.clearingFormList = [];
                        if (this.formData.clearing_form != null && this.formData.clearing_form != '') {
                            if (this.formData.clearing_form.indexOf('+') > 0) {
                                let s = this.formData.clearing_form.split('+');
                                for (let i = 0; i < s.length; i++) {
                                    this.clearingFormList.push(s[i])
                               }
                           } else {
                                this.clearingFormList.push(this.formData.clearing_form)
                           }
                       }
                        this.pageSize = this.dataSize % 10 == 0 ? this.dataSize / 10 : this.$XEUtils.toInteger((this.dataSize / 10)) + 1;
                        this.splitTableDataMain();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
       },
        created() {
            this.formData.bill_id_kd = this.$route.query.bill_id_kd;
            if (this.$route.query.BackOrGoId != null && this.$route.query.BackOrGoId != '') {
                this.warehouseBackOrGo.id = this.$route.query.BackOrGoId
                this.warehouseBackOrGo.name = this.$route.query.BackOrGoName

            }
            if (this.$route.query.company_uuid != null && this.$route.query.company_uuid != '') {
                this.companyBackOrGo.company_uuid = this.$route.query.company_uuid
                this.companyBackOrGo.company_name = this.$route.query.company_name
            }
            this.searchWarehouseBill();

       }
   }


</script>

<style scoped>
    th, td {
        text-align: initial !important;
   }

    .my-list-row {
        display: inline-block;
        width: 100%;
   }

    .my-list-col {
        float: left;
        width: 33.33%;
   }

    .my-top, .my-bottom {
        font-size: 12px;
   }

    .my-top {
        margin-bottom: 5px;
   }

    .my-bottom {
        margin-top: 30px;
        text-align: right;
   }
</style>
